import { Link } from 'react-router-dom';
import './Header.css'
import { useEffect, useState } from 'react';

const Header = () => {

    const [navColor, setNavColor] = useState(false);
    const [openAside, setOpenAside] = useState(false);
    const [hiddenNav, setHiddenNav] = useState(window.outerWidth > 780 ? false : true)

  useEffect(() => {
    const handleScroll = () => {
      const yPos = window.scrollY;
      
      // Defina o limite onde você deseja que a cor mude
      const scrollThreshold = 130;
      
      
      // Ajuste conforme necessário
      if (yPos > scrollThreshold) {
        setNavColor(true);
// Defina a cor desejada quando o scroll passar do limite
      } else {
        setNavColor(false); // Defina a cor inicial da navbar
      }
    };

    const handleResize = () => {
      const xPos = window.outerWidth
      if(xPos > 770) {
        setHiddenNav(false)
        setOpenAside(false)
      } else {
        setHiddenNav(true)
        setOpenAside(false)
      }
    }

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    // Retorne a função de limpeza para remover o event listener quando o componente for desmontado
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Este array vazio garante que o useEffect só será executado uma vez, equivalente a componentDidMount

  function scrollToServicos() {
    setOpenAside(false)
    const element = document.getElementById('servicos');
    element.scrollIntoView({behavior:'smooth',block:'start'})
    
  }

  function scrollToSobreNos() {
    setOpenAside(false)
    const element = document.getElementById('sobrenos');
    element.scrollIntoView({behavior:'smooth',block:'start'})
  }

  function scrollToContato() {
    setOpenAside(false)
    const element = document.getElementById('contato');
    element.scrollIntoView({behavior:'smooth',block:'start'})
  }
return(
  <>
    <header style={{'background':navColor?'rgba(0,0,0,0.75)':'transparent',
    'paddingTop':navColor?'2px':'12px',
    'paddingBottom':navColor?'2px':'12px'}}>
      <div className='desktop-bar'>
        <div style={{'display':'flex'}}>
        <Link to={'/'}>
              <img id='images' style={{'filter':navColor?'none':'brightness(10)'}} className='logo' src='/img/Logo.png' alt='CGC LOGO'/></Link>
        </div>
        
          <nav style={{'display': hiddenNav ? 'none':'flex'}}>
              <button onClick={scrollToServicos}>Serviços</button>
              <button onClick={scrollToSobreNos}>Sobre nós</button>
              <button onClick={scrollToContato}>Contato</button>
              <Link>Fale com um especialista <i className='fa fa-arrow-right'></i></Link>
          </nav>
          <button style={{'display':openAside || !hiddenNav ? 'none':'block'}} onClick={e=>setOpenAside(!openAside)} className='menu'><i className='fa fa-bars'></i></button>

      </div>
      
    </header>

    <aside className={openAside?'close':'open'}>
        <button style={{'display':openAside? 'block':'none'}} onClick={e=>setOpenAside(!openAside)} className='menu'><i className='fa fa-xmark'></i></button>
        <nav>
          <button onClick={scrollToServicos}>Serviços</button>
          <button onClick={scrollToSobreNos}>Sobre nós</button>
          <button onClick={scrollToContato}>Contato</button>
          <Link>Fale com um consultor <i className='fa fa-arrow-right'></i></Link>
        </nav>
      </aside>
      <div className='aside-blur' style={{'display':openAside ? 'block':'none'}}></div>
    </>
);
}

export default Header;