import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import { useEffect, useState } from 'react';

function App() {

  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleImagesLoaded = () => {
    setLoading(false);
  };
  useEffect(() => {
    const images = document.querySelectorAll('img');
    let loadedCount = 0;

    const updateProgress = () => {
      loadedCount++;
      const calculatedProgress = (loadedCount / images.length) * 100;
      setProgress(Math.round(calculatedProgress));

      
        if (calculatedProgress >= 100) {
          setInterval(() => {
            handleImagesLoaded();
            var loading = document.getElementById('loading')
            loading.style.transform = 'translateY(-100%)';
            
            document.getElementById('main').style.display = 'block'
          },500)
      }
    };

    images.forEach((image) => {
      if (image.complete) {
        updateProgress();
      } else {
        image.addEventListener('load', updateProgress);
      }
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener('load', updateProgress);
      });
    };
    
  }, []);

  return (
    <>
    <div className='loading' id='loading'>
      <img src='/img/Logo.png' alt='logo cgc'/>
      <p>Bem vindo!</p>
        <div style={{ width: '100px', backgroundColor: 'black' }}>
            <div
              style={{
                width: `${progress}%`,
                height: '3px',
                backgroundColor: '#fff',
              }}
            ></div>
          </div>
          <p>{progress} %</p>
    </div>
    <Routes>
      <Route path='/' element={<Home handleImagesLoaded={handleImagesLoaded} />}/>
    </Routes>
    </>
  );
}

export default App;
