import { Link } from 'react-router-dom';
import './Footer.css'

const Footer = () => {
return(
    <footer>
        <div>
            <Link to={'https://www.instagram.com/jetgrouting/'}><i className='fa-brands fa-instagram'></i>@jetgrouting</Link>
            <p>tel.: +55 (11) 2614-3363</p>
            <p>contato: comercial@cgcbr.com.br</p>
            <p> © {new Date().getFullYear()} por CGC Geotecnia e Construções Eireli</p>
        </div>
        <div>
            <button onClick={e=> document.getElementById('root').scrollIntoView({top:0, behavior:'smooth'})}><i className='fa-solid fa-arrow-up-long'></i></button>
            <img src="/img/Logo.png" alt='Logo CGC' />
            
        </div>
    </footer>
);
}

export default Footer;