import React, { useEffect, useState, useRef } from 'react';

function ScrollAppear({ children, animatedDirection,visibleCb, setIsVisibleCb,normallyEnd }) {
  const [isVisible, setIsVisible] = useState(false);
  const [hasAppeared, setHasAppeared] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    function handleScroll() {
      if(componentRef.current) {
        const currentScrollY = window.scrollY;
        const componentPosition = componentRef.current.offsetTop;
        const { height, top } = componentRef.current.getBoundingClientRect()
          if(normallyEnd) {
            if (currentScrollY > componentPosition - window.innerHeight + 400 &&
              currentScrollY < componentPosition - window.innerHeight + height + 220 ) {
              if(setIsVisibleCb) {
                setIsVisibleCb(true);
              }
            } else {
              if(setIsVisibleCb) {
                setIsVisibleCb(false)
              }
            }
          } else {
            if (currentScrollY > componentPosition - window.innerHeight +100) {
              if(setIsVisibleCb) {
                setIsVisibleCb(true);
              }
            } else {
              if(setIsVisibleCb) {
                setIsVisibleCb(false)
              }
            }
          }
          
        if (!hasAppeared && componentRef.current) {
          if (currentScrollY > componentPosition - window.innerHeight + 150) {
            setIsVisible(true);
            setHasAppeared(true); 
            // Marca que o efeito já ocorreu
          }
        }
      }
      
      
    }

    window.addEventListener('scroll', handleScroll);

    // Limpa o event listener quando o componente é desmontado
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasAppeared,setIsVisibleCb,visibleCb]); // Executa o useEffect quando hasAppeared muda

  return (
    <div ref={componentRef} 
    style={{ opacity: isVisible ? 1 : 0, 
      transition: '.8s ease', 'transform':animatedDirection==='right'?
  'translateX(-60%)' : 
  animatedDirection==='left' ? 'translateX(-60%)':
  'translateX(0)'
  }}
  className={isVisible ?'visible':''}
  >
      {children}
    </div>
  );
}

export default ScrollAppear