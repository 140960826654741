import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import './Home.css';
import React, { useEffect, useRef, useState } from 'react';
import ScrollAppear from '../../components/ScrollAppear';

const Home = ({handleImagesLoaded}) => {

    const carouselPortfolioRef = useRef(null);
    const cardWidth = useRef(0);
    const [hasAppeared,setHasAppeared] = useState(false);
    const [isVisible,setIsVisible] = useState(false);

    function changeStatus(e) {
      setIsVisible(e)
    }

    function setWidthCard() {
      if (carouselPortfolioRef.current) {
        const card = carouselPortfolioRef.current.querySelector('.card');

        if(card) {  
          console.log(card.offsetWidth)
          cardWidth.current=card.offsetWidth;
        }
      }
    }

    useEffect(()=>{
      handleImagesLoaded();
    },[handleImagesLoaded])

    function scrollLeft() {
      
        if (carouselPortfolioRef.current) {
            carouselPortfolioRef.current.scrollLeft -= cardWidth.current;
          }
    }

    function scrollRight() {
        if (carouselPortfolioRef.current) {
            carouselPortfolioRef.current.scrollLeft += cardWidth.current;
        }
    }

    const handleScroll = () => {
      
      if (
        carouselPortfolioRef.current &&
        carouselPortfolioRef.current.offsetWidth + carouselPortfolioRef.current.scrollLeft >=
          carouselPortfolioRef.current.scrollWidth - 1
      ) {
        document.getElementById('rightPortfolio').style.visibility = 'hidden';
      } else {
        document.getElementById('rightPortfolio').style.visibility = 'visible';
      }
  
      if (carouselPortfolioRef.current && carouselPortfolioRef.current.scrollLeft === 0) {
        document.getElementById('leftPortfolio').style.visibility = 'hidden';
      } else {
        document.getElementById('leftPortfolio').style.visibility = 'visible';
      }
      };

    const clientes = [
      <img src='/img/vale.png' alt='logo vale' id='vale'/>,
      <img src='/img/metro-sp.png' alt='logo metro sp' id='metro'/>,
      <img src='/img/cptm.png' alt='logo cptm' id='cptm'/>,
      <img src='/img/sabesp.png' alt='logo sabesp' id='sabesp'/>,
      <img src='/img/samarco.png' alt='logo samarco' id='samarco'/>,
      <img src='/img/metrorio.png' alt='logo metro rio' id='metrorio'/>,
      <img src='/img/Enpasa.png' alt='logo enpasa' id='enpasa'/>,
      <img src='/img/AG.png' alt='logo ag' id='ag'/>,
      <img src='/img/Enotec.png' alt='logo enotec' id='enotec'/>,
      <img src='/img/dpbarros.png' alt='logo dpbarros' id='dpbarros'/>
    ]
      
    function scrollToPortfolio() {
        const element = document.getElementById('portfolio');
        element.scrollIntoView({behavior:'smooth',block:'nearest'})
    }

    const cards = [
      {
        title:'Injeções de Impermeabilização',
        local:'Barueri - SP',
        detalhe:'A instalação dos tubos de concreto enfrentou desafios devido ao elevado volume de água e ao solo arenoso do local, o que tornou as escavações especialmente difíceis. Para superar essas dificuldades, adotamos a técnica de tratamento do solo com o Ecoryon, empregando equipamentos compactos para otimizar o processo.'
      },
      {
        title:'Impermeabilização das Juntas da Parede Diafragma',
        local:'Av. Getúlio Vargas - Rio de Janeiro - RJ',
        detalhe:'Nesta obra executamos o serviço nas juntas da parede diafragma de um prédio comercial. No local havia fluxos de águas carreando areia para dentro do subsolo. Duas colunas de injeção de ECORYON foram executadas, desta maneira a parede ficou protegidas das infiltrações que poderiam causar subpressão.'
      },
      {
        title:'Impermeabilização do Poço de Visita (PV)',
        local:'Rod. Anhanguera - São Paulo - SP',
        detalhe:'Projeto de túnel de travessia sob a Rodovia Anhanguera. No local havia percolação de água com arraste de material solto ao redor do poço e,portanto, impossibilitava o avanço da escavação. Por isso executamos a injeção de ECORYON para impermeabilizar o solo ao redor do PV.'
      },
      {
        title:'Injeção de Estabilização de Recalques',
        local:'Rio de Janeiro - RJ',
        detalhe:'As injeções de estabilização de recalques foram executadas nas edificações vizinhas ao prédio em construção. Para impedir o fluxo de água, injetamos ECORYON. As injeções de LW e nata de cimento foram aplicadas para preencher vazios, consolidar e estabilizar o subsolo contra o recalque.'
      },
      {
        title:'Impermeabilização abaixo da parede Diafragma',
        local:'Rio de Janeiro - RJ',
        detalhe:'Havia um prédio que foi demolido cujo local está sendo construído um novo edifício de 15 andares com dois subsolos. No entanto, a água subterrânea começou a passar entre a rocha e o fundo das lamelas da parede. Foram avaliados diversos fatores, por exemplo, a pressão da injeção, volume, vazão, tempo de consolidação e a profundidade do topo da rocha. A partir disso foi possível realizar uma injeção ajustada'
      },
      {
        title:'Impermeabilização abaixo da parede Diafragma (ECORYON)',
        local:'Brooklin - São Paulo - SP',
        detalhe:'Havia uma ocorrência de percolação de água com arraste de material sólido por baixo da parede diafragma. Foi então elaborado um projeto de injeção de ECORYON depois de uma profunda análise das condições e situações do local ( área tratada + volume estimado ).'
      }
    ]

    const [scrollPosition, setScrollPosition] = useState(0);

    // Atualiza a posição do scroll ao rolar a página
    const handleScrollText = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    useEffect(() => {
    setInterval(()=> {
      setHasAppeared(true);
      setWidthCard();
    },1200);
    
    window.addEventListener('scroll', handleScrollText);

    // Remove o event listener quando o componente é desmontado
    return () => {
      window.removeEventListener('scroll', handleScrollText);
    };
  }, []); // O segundo argumento vazio garante que o efeito será executado apenas uma vez (equivalente a componentDidMount)
    return(
        <div className='main' id='main'>
              <Header />
            <main>
                <div className="banner">
                    <div className='gradient'></div>
                    <img id='images' alt='banner CGC' src='/img/banner.png'/>
                    <div className={`contents ${hasAppeared?'visible':''}`}
                    style={{ top: `${200 + scrollPosition * 0.3}px` ,
                 }}>
                    <div className='intro' >
                        <p>Confira nossos melhores serviços.</p>
                        <h1>PROBLEMAS GEOTÉCNICOS?</h1>
                        <p>Podemos te ajudar na solução para casos<br /> de tratamento e reforço de solos</p>
                        <div className='group-button'>
                            <Link>Solicitar Orçamento</Link>
                            <button onClick={scrollToPortfolio}>Ver Trabalhos</button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className={`clientes ${hasAppeared?'visible':''}`}>
                    <h2>Clientes que depositam sua confiança em nós</h2>
                    <div className='slider-wrapper'>
                        
                        <div className='slider'>
                            {clientes.map((cliente,index) => {
                              return(
                                <div key={index+1}>{cliente}</div>
                              )
                            })}
                        </div>
                        <div className='slider'>
                            {clientes.map((cliente,index) => {
                              return(
                                <div key={index+1}>{cliente}</div>
                              )
                            })}
                        </div>
                        
                    </div>
                </div>

                <div
                style={{
                  'background':isVisible?'black':'white',
                  'color':isVisible?'#fff':'black'
                }}
                className='servicos'  id='servicos'>
                    <div>
                      <p style={{'color':'var(--primary01)'}}></p>
                      <h2>Nossas especialidades</h2>
                    </div>
                    <ScrollAppear animatedDirection={'right'}>
                    <div className='cards'>
                        <div className='card-wrapper'>
                          <div className='card'>
                              <img src='/img/jet-grouting.png' alt='jet grouting imagem'
                              style={{
                                'transform':
                                `translate3d(0px, ${((scrollPosition*4)/100)-20}%, 0px) scale3d(1.2, 1.2, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
                                'transformStyle': 'preserve-3d',
                                'willChange': 'transform'
                              }}
                              />
                          </div>
                          <h3>Jet <span>Grouting</span></h3>
                          <p>Jet grouting é um método de estabilização de solo que envolve a injeção de cimento ou outro agente de ligação sob alta pressão no solo para formar uma coluna de solo-cimento</p>
                          <Link>Consultar um especialista <i className='fa fa-arrow-right'></i></Link>
                        </div>
                        <div className='card-wrapper'>
                          <div className='card'>
                              <img src='/img/chemical.png' alt='chemical grouting imagem' 
                              style={{
                                'transform':
                                `translate3d(0px, ${((scrollPosition*2.5)/100)-20}%, 0px) scale3d(1.2, 1.2, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
                                'transformStyle': 'preserve-3d',
                                'willChange': 'transform'
                              }}
                              />
                          </div>
                          <h3>Chemical <span>Grouting</span></h3>
                          <p>Chemical grouting é um método de estabilização de solo que envolve a injeção de compostos químicos líquidos ou em gel no solo para melhorar suas propriedades hidráulicas</p>
                          <Link>Consultar um especialista <i className='fa fa-arrow-right'></i> </Link>
                        </div>
                        
                       
                    </div>
                    </ScrollAppear>
                    
                </div>

                <ScrollAppear>
                <div 
                style={{
                  'background':isVisible?'black':'white',
                  'color':isVisible?'#fff':'black'
                }}
                className='beneficios'>
                    <p>Porque nos escolher</p>
                    <h3>Os benefícios de utilizar nossos serviços</h3>
                    <div className='lista'>
                      <div className='row'>
                          <div>
                              <img src="/img/beneficio1.png" alt='equipe'/>
                              <p>Equipe altamente<br/> capacitada</p>
                          </div>
                          <div>
                              <img src="/img/beneficio2.png" alt='seguranca'/>
                              <p>Segurança em<br/> seu investimento</p>
                          </div>
                          <div>
                              <img src="/img/beneficio3.png" alt='compromisso'/>
                              <p>Compromisso<br/> com a qualidade</p>
                          </div>
                          </div>
                        <div className='row'>
                          <div>
                              <img src="/img/beneficio4.png" alt='custos'/>
                              <p>Avaliação<br/> de custos</p>
                          </div>
                          <div>
                              <img src="/img/beneficio5.png" alt='qualidade'/>
                              <p>Qualidade</p>
                          </div>
                          <div>
                              <img src="/img/beneficio6.png" alt='entrega'/>
                              <p>Excelência<br/> e entrega</p>
                          </div>
                        </div>
                    </div>
                </div>
                </ScrollAppear>

                <div 
                style={{
                  'background':isVisible?'black':'white',
                  'color':isVisible?'#fff':'black'
                }}
                className='portfolio' id='portfolio'>
                  <div>
                  <p style={{'color':'var(--primary01)','textAlign':'center'}}>Portfólio</p>
                  <h2
                  style={{
                    'color':isVisible?'#fff':'black',
                    'textAlign':'center'
                  }}
                  >Histórias de sucesso</h2>
                  </div>
                  
                  <ScrollAppear 
                  setIsVisibleCb={changeStatus} visibleCb={isVisible}
                  normallyEnd={false}
                  animatedDirection={'left'}>
                    <div className="carousel-wrapper">
                        <button onClick={scrollLeft} id="leftPortfolio" ><i onClick={scrollLeft} className="fa-solid fa-angle-left"></i></button>
                        <div ref={carouselPortfolioRef} className='carousel' onScroll={handleScroll} >
                          {cards.map((card,i) => {
                            return(
                              <div key={`port ${i}`} className='card'>
                                <img src={`/img/port${i+1}.png`} alt={`portfolio foto ${i+1}`} className='imagem por'/>
                                <div className='info'>
                                  <p>{card.title}</p>
                                  <p>{card.local}</p>
                                </div>
                                <div className='detalhe'>
                                  <p>{card.detalhe}</p>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <button onClick={scrollRight} id="rightPortfolio"><i onClick={scrollRight}  className="fa-solid fa-angle-right"></i></button> 
                        
                    </div>
                  </ScrollAppear>
                  <button className='calltoact'>Falar com um especialista</button>
                </div>

                <ScrollAppear 
                
                animatedDirection={'right'}>
                <div className='sobrenos' id='sobrenos'
                >
                    <div className='texto'>
                      <p>Sobre nós</p>
                      <h3>Tecnologias japonesas em avanço no Brasil</h3>
                      <hr /><br /><br />
                      <p>A Geotecnia brasileira moderna conta com a participação no Brasil de <strong>know how japonês</strong>! Como já é de conhecimento em todo o mundo, o <strong>Jet Grouting</strong> foi criado no Japão em 1965, tornando-se tecnologia consagrada de uso comum em obras de grande porte e de alta complexidade.</p>
                      <div style={{'height':'1px'}}></div>
                      <p>A empresa CGC, de origem japonesa, sediada no Brasil desde 2008, trouxe técnicas avançadas, executando colunas de até 3,50m de diâmetro a partir de perfurações com hastes Ø 90mm. Além das <strong>técnicas avançadas</strong> de Jet Grouting, também introduziu novas soluções em geotecnia com injeções em larga escala de fluidos consolidantes em via dupla, que se misturam somente na saída, na extremidade da haste de perfuração, que se presta também às injeções.</p>

                    </div>
                    
                      <img src='/img/cgc-sobrenos.png' alt='cgc foto'/>
                  
                    
                </div>
                </ScrollAppear>

                <div className="estat">
                  
                  <h2>Conheça mais da nossa história</h2>
                  <ScrollAppear>
                    <div className='numeros'>
                        <div>
                            <p><span>+15</span> anos de história</p>
                        </div>
                        <div>
                            <p><span>+350</span> obras</p>
                        </div>
                        <div>
                            <p>em <span>todo</span> território nacional</p>
                        </div>
                    </div>
                    </ScrollAppear>
                    
                </div>

                <ScrollAppear
                animatedDirection={'left'}>
                <div
                className='sobrenos-2'>
                      <img 
                      
                      src='/img/cgc-sobrenos-2.png' alt='cgc foto' />

                      <div className='texto'>
                        <p>As tecnologias de injeção de fluidos em via dupla, oferecem em especial o tratamento de solos predominantemente arenosos, diminuindo consideravelmente a permeabilidade e concedendo certa coesão, permitindo escavações abaixo do nível do lençol freático em segurança, sem fluxos d’água indesejáveis.</p>
                        <p>O espaço subterrâneo torna-se agora um ambiente de ocupação com possibilidades infinitas, com <strong>soluções criativas de tecnologias</strong> inusitadas de muitos recursos.</p>
                        <p>São técnicas também utilizadas em escavações onde ocorrem infiltrações inesperadas, como túneis, poços, garagens subterrâneas, METRÔs e outros.</p>
                        <p style={{'borderBottom':'1px solid rgba(200,200,200,0.9)','paddingBottom':'48px'}}>A CGC com <strong>alto discernimento técnico</strong> e intensa experiência nas aplicações destas tecnologias, oferece as especificações em detalhes para que consultores e projetistas possam considerar estas tecnologias como soluções em seus projetos e obras.</p>
                        <button className='calltoact'>Falar com especialista</button>
                      </div>
                  </div>
                  </ScrollAppear>
                <div className='calltoact'>
                    <img src="/img/image01.png" alt='background imagem cgc'/>
                    <h4>Clique abaixo para ser redirecionado ao nosso contato</h4>
                    <Link>Quero entrar em contato com um especialista</Link>
                </div>

                <div className='contato' id='contato'>
                    <div>
                      <p>Entre em contato</p>
                      <p>Deixe a sua mensagem</p>
                      <p>Dúvidas sobre seu projeto? Entre em contato por meio do telefone.<br/> (11) 2614-3363 ou a partir do formulário. Podemos discutir todos os detalhes, para garantir a melhor qualidade do serviço e entrega.</p>
                    </div>
                    <form>
                      <div>
                        <label>Empresa</label>
                        <input type='text' />
                      </div>
                      <div>
                        <label>E-mail</label>
                        <input type='email' />
                      </div>
                      <div>
                        <label>Telefone / Celular</label>
                        <input />
                      </div>
                      <div>
                        <label>Mensagem (opcional)</label>
                        <textarea></textarea>
                      </div>
                      <button type='submit'>Enviar mensagem</button>

                    </form>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default Home;